import { Section } from './classes'
import { AllowVideo } from './allowVideo'
import { WalletSummary } from './wallet'
import { Quarter } from './quarters'
import { UserInterval } from './intervals'

export enum UserPosition {
  student = 'student',
  staff = 'staff',
}

export enum Role {
  listUser = 'listUser',
  createUser = 'createUser',
  create = 'create',
  delete = 'delete',
  update = 'update',
  anyRole = 'anyRole',
}

export interface QuarterProfile {
  id: number
  quarterId: number
  grade: string
  registrationState: string
  dropState: string
  state: string
  userId: number
  remark: string
  user: Partial<User>
  quarter: Partial<Quarter>
  quarterFiles: Partial<QuarterFile>[]
}

export interface User {
  id: number
  authId: number
  username: string
  firstName: string
  firstNameEn: string
  lastName: string
  lastNameEn: string
  nickname: string
  nicknameEn: string
  email: string
  position: UserPosition
  phone: string[]
  roles: Role[]
  createdAt: string
  updatedAt: string
  eligibleSections: Partial<Section>[]
  registeredSections: Partial<Section>[]
  recommendedSections: Partial<Section>[]
  quarterProfile: Partial<QuarterProfile>
  quarterProfiles: Partial<QuarterProfile>[]
  allowVideos: Partial<AllowVideo>[]
  walletSummaries: Partial<WalletSummary>[]
  profilePicture: Partial<FileResponse>
  userInterval: Partial<UserInterval>
}

export interface QuarterFile {
  id: number
  type: string
  quarterProfileId: number
  extension: string
  deleted: boolean
  url: string
  quarterProfile: Partial<QuarterProfile>
}

export interface FileResponse {
  contentType: string
  signedUrl: string
}
