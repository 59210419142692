
















































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import gql from 'graphql-tag'
import { User, UserPosition } from '../types/user'

@Component({
  computed: {
    quarterLoad: () => vxm.quarter.isLoading,
    quarters: () =>
      vxm.quarter.allQuarters.map(el => ({ text: el.name, value: el.id })),
    quarter: {
      get() {
        return vxm.quarter.activeQuarter.id
      },
      set(e: number) {
        vxm.quarter.setActiveQuarterById(e)
      },
    },
    search: () => vxm.globalSearch.getVal,
  },
})
export default class extends Vue {
  private quarter!: number
  private onFetch = false
  private users: Partial<User>[] = []
  private registrationStates = [
    { text: 'All', value: 'all' },
    { text: 'Unregister', value: 'unregister' },
    { text: 'Registered CR', value: 'registeredCourse' },
    { text: 'Transfered CR', value: 'transferedCourse' },
    { text: 'Registered HB', value: 'registeredHybrid' },
    { text: 'Approved', value: 'approved' },
    { text: 'Finished', value: 'finished' },
  ]
  private registrationState = 'all'
  private colorTags = [
    'red',
    'yellow',
    'green',
    'blue',
    'purple',
    'pink lighten-4',
    'orange lighten-2',
  ]

  @Watch('quarter', { immediate: true })
  onQuarterChange(val: number) {
    if (val) {
      this.fetchUser()
    }
  }

  mounted() {
    vxm.quarter.fetchQuarters()
  }

  private get filteredUser() {
    const search = vxm.globalSearch.getVal.toLowerCase()
    return this.users.filter(el => {
      return (
        el.quarterProfile?.dropState !== 'dropped' &&
        (this.registrationState === 'all'
          ? true
          : el.quarterProfile?.registrationState === this.registrationState) &&
        (search
          ? `${el.username};${el.firstName};${el.lastName};${
              el.nickname
            };${el.quarterProfile?.grade?.toLowerCase()}`.indexOf(search) >= 0
          : true)
      )
    })
  }

  private async fetchUser() {
    this.onFetch = true
    const { data } = await this.$apollo.query<{ users: Partial<User>[] }>({
      query: gql`
        query GetAllUserProfile($data: Int!) {
          users {
            id
            username
            firstName
            lastName
            nickname
            position
            quarterProfile(quarterId: $data) {
              id
              grade
              registrationState
              dropState
              remark
            }
          }
        }
      `,
      variables: {
        data: this.quarter,
      },
      fetchPolicy: 'no-cache',
    })
    if (data) {
      this.users = data.users
        .filter(el => el.position === UserPosition.student)
        .sort((a, b) => +(a.username || 0) - +(b.username || 0))
    }
    this.onFetch = false
  }

  private async changeColor(user: User, color: string) {
    this.onFetch = true
    const { data } = await this.$apollo.mutate({
      mutation: gql`
        mutation UpdateRemark($data: EditQuarterProfileArgs!) {
          updateQuarterProfile(data: $data) {
            remark
          }
        }
      `,
      variables: {
        data: {
          userId: user.id,
          quarterId: this.quarter,
          remark: color,
        },
      },
    })
    const userIdx = this.users.findIndex(u => u.id === user.id)
    const quarterProfile = this.users[userIdx].quarterProfile
    if (quarterProfile) {
      quarterProfile.remark = data.updateQuarterProfile.remark
    }
    this.onFetch = false
  }
}
